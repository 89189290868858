<template>
  <b-navbar
    v-on-scroll="handleScroll"
    toggleable="md"
    class="header"
    :class="{
      'header--transparent': !headerMode,
      'header--white': headerMode,
    }"
    fixed="top"
  >
    <b-container>
      <!-- logo -->
      <b-navbar-brand
        :title="$t('label.home')"
        class="header__logo-container"
        to="/home"
        @click="closeMenu"
      >
        <b-img
          v-if="activeMode || headerMode"
          :src="require('@/assets/imgs/logo_d.svg')"
          class="header__logo"
          alt="Logo"
        />
        <b-img
          v-else
          :src="require('@/assets/imgs/logo.svg')"
          class="header__logo"
          alt="Logo"
        />
      </b-navbar-brand>
      <!-- logo -->

      <!-- collapsable part -->
      <b-collapse id="nav-collapse" v-model="collapseChecker" is-nav>
        <!-- nav links -->
        <b-navbar-nav class="header__nav-center ml-auto mr-auto">
          <!-- home nav Link -->
          <b-nav-item to="/home" exact @click="closeMenu">{{
            $t("label.home")
          }}</b-nav-item>
          <!-- home nav Link -->
          <!-- other nav Links -->
          <b-nav-item
            v-for="(navLink, index) in navLinks"
            :key="index"
            :to="navLink.route"
            @click="closeMenu"
            >{{ navLink.title }}</b-nav-item
          >
          <!-- <b-nav-item-dropdown
            id="my-nav-dropdown"
            :text="$t('label.our_work')"
            toggle-class="nav-link-custom"
            right
          >
            <b-dropdown-item
              v-for="work in workItems"
              :key="work.index"
              :to="{ name: 'work-details', params: { slug: work.slug } }"
              >{{ work.name }}</b-dropdown-item
            >
          </b-nav-item-dropdown> -->
          <!-- other nav Links -->
        </b-navbar-nav>
        <!-- nav links -->
      </b-collapse>
      <!-- collapsable part -->

      <!-- aligned last nav items (Language) -->
      <b-navbar-nav
        class="header__nav-last"
        :class="{ 'ml-auto': locale === 'en', 'mr-auto': locale === 'ar' }"
      >
        <b-button
          class="lang-btn button button--filled-primary"
          @click="handleLang"
        >
          <i class="icon-language"></i>
          <span class="lang-btn--text">
            {{ locale === "en" ? $t("label.arabic") : $t("label.english") }}
          </span>
        </b-button>
      </b-navbar-nav>
      <!-- aligned last nav items (Language) -->

      <!-- toggle burger -->
      <b-navbar-toggle
        class="header__burger-btn"
        target="nav-collapse"
        @click="collapseChecker = !collapseChecker"
      ></b-navbar-toggle>
      <!-- toggle burger -->
    </b-container>
  </b-navbar>
</template>

<script>
import Cookies from "js-cookie";
import { mapActions } from "vuex";
import { IndexData } from "@/helpers/apiMethods";

export default {
  name: "Header",
  data() {
    return {
      locale: Cookies.get("language"),
      collapseChecker: false,
      headerMode: false, // transparent => false & white => true
      activeMode: false,
      navLinks: [
        {
          route: "/about-us",
          title: this.$t("label.about_us"),
        },
        // {
        //   route: "/work-details",
        //   title: this.$t("label.our_work")
        // },
        {
          route: "/services",
          title: this.$t("label.services"),
        },
        {
          route: "/store",
          title: this.$t("label.store"),
        },
        {
          route: "/our-works",
          title: this.$t("label.our_works"),
        },
        {
          route: "/blog",
          title: this.$t("label.blog")
        },
        {
          route: "/media",
          title: this.$t("label.media"),
        },
        {
          route: "/contact-us",
          title: this.$t("label.contact_us"),
        },
      ],
      workItems: [],
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        // need to use (to) but don't know what to use it in, so sorry for that ^_^
       
        if (to.path) {
          if (from.path == "/order" || from.path == "/demo") {
            this.closeOverlay();
          }
        }
        this.checkRoute();
      },
    },
  },
  mounted() {
    this.checkOrderActive();
    this.checkRoute();
    this.setVuexData();
    this.handleGetWorks();
  },
  methods: {
    ...mapActions(["setServices", "setSettings"]),
    closeMenu() {
      this.collapseChecker = false;
    },
    handleLang() {
      this.locale = this.locale === "en" ? "ar" : "en";
      Cookies.set("language", this.locale);
      this.$nextTick(() => this.$store.commit("SET_LANG", this.locale));

      this.closeMenu();
    },
    handleScroll(event, el) {
      if (window.scrollY >= 80) {
        el.classList.add("header--active");
        this.activeMode = true;
      } else {
        el.classList.remove("header--active");
        this.activeMode = false;
      }
    },
    checkOrderActive() {
      if (this.$route.path == "/order" || this.$route.path == "/demo") {
        this.$router.go(-1);
      }
    },
    checkRoute() {
      if (this.$route.name === "home" || this.$route.name === "default") {
        this.headerMode = false;
      } else {
        this.headerMode = true;
      }
    },
    setVuexData() {
      this.$store.dispatch("setServices");
    },
    closeOverlay() {
      this.$store.dispatch("SetGlobalOverlay", {
        type: "",
        isOpen: false,
      });
    },
    async handleGetWorks() {
      try {
        const {
          data: { works },
        } = await IndexData({ reqName: "works" });
        this.workItems = works;
   
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
